export default [
  {
    title: "1º BACHILLER",
    horizontal: true,
    data: [
      {
        key: "1",
        text: "MATEMÁTICAS I",
        uri: "/imagenes/apuntes/MatemáticasI.jpg",
        link:
          "https://drive.google.com/drive/folders/1jSkYFk4dirRK-lBmQYVF9Ey5quPXB-VT?usp=sharing"
      },
      {
        key: "2",
        text: "MATEMÁTICAS CCSS",
        uri: "/imagenes/apuntes/MatemáticasCCSSI.jpg",
        link:
          "https://drive.google.com/drive/folders/16IrR5NM4b6ZsZ9EG-GQVK6xYsm9_U1YD?usp=sharing"
      },

      {
        key: "3",
        text: "FÍSICA",
        uri: "/imagenes/apuntes/FísicaI.jpg",
        link:
          "https://drive.google.com/drive/folders/1GEQ_4q5_8S4xbyqpsZSaGOLMP8L8Dpua?usp=sharing"
      },
      {
        key: "4",
        text: "QUÍMICA",
        uri: "/imagenes/apuntes/QuímicaI.jpg",
        link:
          "https://drive.google.com/drive/folders/1GEQ_4q5_8S4xbyqpsZSaGOLMP8L8Dpua?usp=sharing"
      }
    ]
  },
  {
    title: "2º BACHILLER",
    horizontal: true,
    data: [
      {
        key: "1",
        text: "MATEMÁTICAS II",
        uri: "/imagenes/apuntes/MatemáticasII.jpg",
        link:
          "https://drive.google.com/drive/folders/1mBJmGpR25Zd3m0oXQCxOmVNflcynCMOf?usp=sharing"
      },
      {
        key: "2",
        text: "MATEMÁTICAS CCSS",
        uri: "/imagenes/apuntes/MatemáticasCCSSII.jpg",
        link:
          "https://drive.google.com/drive/folders/1rIBe5nkYxQjhVozWazqCUErjC-hCmd-u?usp=sharing"
      },

      {
        key: "3",
        text: "FÍSICA",
        uri: "/imagenes/apuntes/FísicaII.jpg",
        link: "https://drive.google.com/drive/folders/1rmtvLolL9bdiMJ6dt32eyqA-gYM_-2qp?usp=sharing"
      },
      {
        key: "4",
        text: "QUÍMIMCA",
        uri: "/imagenes/apuntes/QuímicaII.jpg",
        link: "https://drive.google.com/drive/folders/1Kf2AUAovfnHuCtH769fcfw9Vih5Skwxo?usp=sharing"
      }
    ]
  },
  {
    title: "SELECTIVIDAD",
    horizontal: true,
    data: [
      {
        key: "1",
        text: "MATEMÁTICAS II",
        uri:
          "/imagenes/apuntes/Selectividad Matemáticas II.jpg",
        link:
          "https://drive.google.com/drive/folders/1F6uX7U0Dz2rEM2bBpT1q4W7k1rb7cPCU?usp=sharing"
      },
      {
        key: "2",
        text: "MATEMÁTICAS CCSS",
        uri:
          "/imagenes/apuntes/Selectividad Matemáticas CCSS.jpg",
        link:
          "https://drive.google.com/drive/folders/1IrBdoLDP1GfU5g3zTgruq0JKo_LXY8uC?usp=sharing"

      },

      {
        key: "3",
        text: "FÍSICA",
        uri: "/imagenes/apuntes/Selectividad Física.jpg",
        link:
        "https://drive.google.com/drive/folders/1VPIePjSdovXTwlMLF9YP9IsIRIritAlT?usp=sharing"

      },
      {
        key: "4",
        text: "QUÍMICA",
        uri:
          "/imagenes/apuntes/Selectividad Química.jpg",
        link:
          "https://drive.google.com/drive/folders/1fjhVZkp85ZKi8hvMr1koESNnpMzkf9ae?usp=sharing"

      }
    ]
  },
   /*
  {
    title: "OTROS",
    horizontal: true,
    data: [
      {
        key: "1",
        text: "MAPAS MUNDO",
        uri: "/imagenes/apuntes/MatemáticasI.jpg",
        link:
          "https://drive.google.com/drive/folders/1jSkYFk4dirRK-lBmQYVF9Ey5quPXB-VT?usp=sharing"
      },
      {
        key: "2",
        text: "PYTHON",
        uri: "/imagenes/apuntes/MatemáticasCCSSI.jpg",
        link:
          "https://drive.google.com/drive/folders/16IrR5NM4b6ZsZ9EG-GQVK6xYsm9_U1YD?usp=sharing"
      },

      {
        key: "3",
        text: "A",
        uri: "/imagenes/apuntes/FísicaI.jpg",
        link:
          "https://drive.google.com/drive/folders/1GEQ_4q5_8S4xbyqpsZSaGOLMP8L8Dpua?usp=sharing"
      },
      {
        key: "4",
        text: "B",
        uri: "/imagenes/apuntes/QuímicaI.jpg",
        link:
          "https://drive.google.com/drive/folders/1GEQ_4q5_8S4xbyqpsZSaGOLMP8L8Dpua?usp=sharing"
      }
    ]
  }
   */
];
